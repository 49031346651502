const data = [
  {
    code: "ND-CA",
    label: "LA",
    size: 120000,
    address: "11089 Tacoma Dr, Rancho Cucamonga, CA 91730",
    contact: ["Emma Zhuang"],
    phone: "(626) 500-9889",
    features: [],
    position: [15, 54],
  },
  {
    code: "SAC-CA",
    label: "SAC",
    size: 200000,
    address: "550 N Pioneer Ave, Suite 200, Woodland, CA 95776",
    contact: ["Simon Kong"],
    phone: "(510) 590-0003",
    features: ["FBM by FedEx, UPS & LTL", "FBA Storage & Trans Shipping"],
    position: [12, 42],
  },
  {
    code: "ML-UT",
    label: "SLT",
    size: 30000,
    address: "1950 N 2200 W, Suite 300, Salt Lake City, UT 84116",
    contact: ["Ashley McLean"],
    phone: "(888) 752-2828",
    features: ["FBM by FedEx, UPS & LTL"],
    position: [28, 40],
  },
  {
    code: "LD-NJ",
    label: "NY/NJ",
    size: 200000,
    address: "1600 Lower Road, Linden, NJ 070",
    contact: ["Emma Wu", "Andy Pang"],
    phone: "(908) 266-0470",
    features: [
      "FBM by FedEx, UPS & LTL",
      "FBA Storage & Trans Shipping",
      "3PL Project",
    ],
    position: [84, 30],
    cardOffset: [-960, 0],
  },
  {
    code: "BC-GA",
    label: "ATL",
    size: 120000,
    address: "2751 Buford Hwy, Suite 200, Buford, GA 30518",
    contact: ["Jose Chang", "Stacy Li"],
    phone: "(470) 760-8113",
    features: [
      "FBM by FedEx, UPS & LTL",
      "FBA Storage & Trans Shipping",
      "Returns Processing",
      "3PL Project",
    ],
    position: [74, 68],
    cardOffset: [-800, 0],
  },
  {
    code: "SAV-GA",
    label: "SAV",
    size: 200000,
    contact: [],
    address: "Coming August 1, 2023",
    position: [78, 67],
    cardOffset: [-550, 0],
  },
  {
    code: "NL-TX",
    label: "HOU",
    size: 130000,
    address: "7207 N Loop E,Houston,Tx 77028",
    contact: ["Jessie Chen"],
    phone: "(725) 216-8580",
    position: [52, 85],
    cardOffset: [-400, -200],
  },
  {
    code: "TEC-MX",
    label: "Baja-MX",
    size: 50000,
    address: "Tecate, Baja California",
    contact: ["Juliana Solis"],
    phone: "juliana@mexus.me",
    features: [],
    position: [11, 74],
  },
]

export default data
